<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Registrar Usuario</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.name)"
                :variant="`light-primary`"
                :to="{ /*name: 'apps-users-view', params: { id: data.item.id }*/ }"
              />
            </template>
            <b-link
              :to="{ /*name: 'apps-users-view', params: { id: data.item.id } */}"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.name }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(roles)="data">
            <b-badge v-for="role in data.item.roles" v-if="item_selected == null"
            pill
            :variant="`light-primary`"
            class="text-capitalize mr-1"
          >
          <span v-if="role.rol">{{ role.rol.nombre }}</span>
          </b-badge>          
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="open_modal(data.item)">
              <feather-icon icon="LockIcon" class="text-warning" />
              <span class="align-middle ml-50">Permisos</span>
            </b-dropdown-item>

           <!--  <b-dropdown-item :to="{ /*name: 'apps-users-edit', params: { id: data.item.id }*/ }">
              <feather-icon icon="EditIcon" class="text-primary"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item> -->

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" class="text-danger"/>
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal v-model="modalShow" @hidden="modalShow = false;" :no-close-on-backdrop="true" @ok="updateRoles()">
          <template #modal-title>
            <feather-icon icon="LinkIcon" size="18" class="align-middle mr-25"/> Asignar Permisos
          </template>
          <template v-if="item_selected">
            <h4 class="text-primary mb-2"><feather-icon icon="UserIcon" size="18" class="align-middle mr-25"/> {{ item_selected.name.toUpperCase()}}</h4>
            <hr>
            <b-form-group
              label="Asignar Roles"
              label-for="roles"
            >
            <b-form-select
                multiple
                id="centro_costo_padre_id"
                v-model="item_selected.roles"
                :options="roles"
                trim>
              </b-form-select>

            </b-form-group>
          </template>
          
        </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal, BFormSelect, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import axios from '@axios';

export default {
  components: {
    UserListAddNew,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormSelect,
    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteUser,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteUser,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }
  },
  data() {
    return {
      modalShow : false,
      item_selected : null,
      roles : [],
    }
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    updateRoles() {
      axios.put('/api/usuarios/'+this.item_selected.id+'/roles', {roles : this.item_selected.roles})
        .then(response => {
          this.$toastr.success('Roles asignados correctamente');
          this.modalShow = false;
          this.item_selected = null;
          //refetchdata
          this.refetchData();
        })
        .catch(error => {
          console.log(error);
          this.item_selected = null;
          this.modalShow = false;
        });
    },
    getRoles() {
      axios.get('/api/roles')
        .then(response => {
          let data = response.data.data;
          //convert data array to value text keys
          data = data.map(function (obj) {
            return { value: obj.Codigo, text: obj.Nombre };
          });
          this.roles = data;
          //this.roles = response.data;

        })
        .catch(error => {
          console.log(error);
        });
    },
    open_modal(item) {
				this.modalShow = true;
				this.item_selected = item;
			},
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
